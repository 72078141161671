import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class CommonService {

  public _apiUrl = '';
  constructor(
    public http: HttpClient
  ) {
    this._apiUrl = environment.api;
  }

  /**
   * @ngdoc method
   * @name apiRequest
   * @description
   * Ap Request
   * @param {String} url
   * @param {object} data
   * @param {String} method
   * @param {Boolean} isPublic to call api without auth header
   * @param {Boolean} isForm to call api with form data
   * @param {*} html
   */
  apiRequest(url : string, data : any, method : string): Observable<any> {
      if (method === 'post') {
        return this.http.post(this._apiUrl + url, data);
      } else if (method === 'put') {
        return this.http.put(this._apiUrl + url, data);
      } else if (method === 'delete') {
        return this.http.delete(this._apiUrl + url);
      } else {
        return this.http.get(this._apiUrl + url, data.hasOwnProperty('params') ? data : { params: data }).pipe(
          retry(2)
        );
      }
  }
}
