import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from '../../../common/common.service';
@Component({
  selector: 'app-email-templates-list-add',
  templateUrl: './email-templates-list-add.component.html',
  styleUrls: ['./email-templates-list-add.component.scss']
})
export class EmailTemplatesListAddComponent implements OnInit {

	emailForm !: FormGroup
	submitted = false;
	formSubmitted = false;
	formReady = false;
	containers : any = [];
	id : string|null = '';
  	constructor(
  		private commonService: CommonService,
  		private fb: FormBuilder,
  		private route: ActivatedRoute,
        private router: Router,
  	) { }

  	ngOnInit(): void {
  		this.getContainers();
  		this.setForm();
  		this.id = this.route.snapshot.paramMap.get('id');
  		if(this.id){
  			this.getTemplate(this.id);
  		}
  	}
  	/**
     * @ngdoc method
     * @description
     */
    get f() {
        return this.emailForm.controls;
    }
    /**
     * @ngdoc method
     * @name getDepartment
     * @description
     * Getting department details
     * @returns void
     */
    getTemplate(id : string){
    	this.commonService.apiRequest('templates/get/'+id, {}, 'get').subscribe(
        (response : any) => {
        	const emailData = response.data;
          	this.emailForm.patchValue({
				...emailData
	        });
        	return;
        },
        (error : any) => {
            return;
        })
    }
    /**
     * @ngdoc method
     * @name setForm
     * @description
     * Init form
     * @returns void
     */
	setForm(){
  		this.emailForm = this.fb.group({
		    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
		    subject: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
		    email_text: ['', [Validators.required]],
		    email_container_id: [''],
		    // email_attachment: [],
		    status: [1, [Validators.required]],
			// labels: []
		});
		this.formReady = true;
  	}

  	getContainers(){
  		this.commonService.apiRequest('containers', {}, 'get').subscribe(
        (response : any) => {
        	console.log(response);
          	if(response.data.length > 0){
          		this.containers = response.data
          	}
        	return;
        },
        (error : any) => {
            return;
        })
  	}
  	onSubmit(){
  		this.submitted = true;
  		this.formSubmitted = true;
  		if (this.emailForm.invalid) {
  			this.formSubmitted = false;
	      	return;
	    }
	    let postUrl = 'templates/store';
	    if(this.id){
	    	postUrl = 'templates/update/'+this.id
	    }
	    this.commonService.apiRequest(postUrl, this.emailForm.value, 'post').subscribe(
        (response : any) => {
          	this.formSubmitted = false;
            Swal({
	            title: 'Success',
	            text: 'Added successfully'
        	})
            this.router.navigate(['/email-templates']);
        	return;
        },
        (error : any) => {
        	this.formSubmitted = false;
            Swal({
	            title: 'Error',
	            text: 'Failed'
        	})
            return;
        })

  	}

}
