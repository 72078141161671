import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from '../../../common/common.service';

@Component({
  selector: 'app-email-container-add',
  templateUrl: './email-container-add.component.html',
  styleUrls: ['./email-container-add.component.scss']
})
export class EmailContainerAddComponent implements OnInit {

	@ViewChild('tinymce') tinymce;
	emailForm !: FormGroup
	submitted = false;
	formSubmitted = false;
	formReady = false;
	departments : any = [];
	id : string|null = '';
  	constructor(
  		private commonService: CommonService,
  		private fb: FormBuilder,
  		private route: ActivatedRoute,
        private router: Router,
  	) { }

  	ngOnInit(): void {
  		this.setForm();
  		this.id = this.route.snapshot.paramMap.get('id');
  		if(this.id){
  			this.getTemplate(this.id);
  		}
  	}
  	/**
     * @ngdoc method
     * @description
     */
    get f() {
        return this.emailForm.controls;
    }
    /**
     * @ngdoc method
     * @name getDepartment
     * @description
     * Getting department details
     * @returns void
     */
    getTemplate(id : string){
    	this.commonService.apiRequest('containers/get/'+id, {}, 'get').subscribe(
        (response : any) => {
        	const emailData = response.data;
          	this.emailForm.patchValue({
				...emailData
	        });
        	return;
        },
        (error : any) => {
            return;
        })
    }
    /**
     * @ngdoc method
     * @name setForm
     * @description
     * Init form
     * @returns void
     */
	setForm(){
  		this.emailForm = this.fb.group({
		    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
		    container_text: ['', [Validators.required]],
		});
		this.formReady = true;
  	}

  	
  	onSubmit(){
  		this.submitted = true;
  		this.formSubmitted = true;
  		if (this.emailForm.invalid) {
  			this.formSubmitted = false;
	      	return;
	    }
	    let postUrl = 'containers/store';
	    if(this.id){
	    	postUrl = 'containers/update/'+this.id
	    }
	    this.commonService.apiRequest(postUrl, this.emailForm.value, 'post').subscribe(
        (response : any) => {
          	this.formSubmitted = false;
            Swal({
	            title: 'Success',
	            text: 'Added successfully'
        	})
            this.router.navigate(['/email-containers']);
        	return;
        },
        (error : any) => {
        	this.formSubmitted = false;
            Swal({
	            title: 'Error',
	            text: 'Failed'
        	})
            return;
        })

  	}

}
