import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
	@Input() title: string = 'Confirm';
  	@Input() message: string = '';
  	@Input() btnOkText: string = 'Yes';
  	@Input() btnCancelText: string = 'No';
  	constructor(private activeModal: NgbActiveModal) { }

  	ngOnInit(): void {
  	}

  	/**
   * @ngdoc method
   * @name decline
   * @description
   * decline the modal
   */
	public decline() {
		this.activeModal.close(false);
	}

	/**
	* @ngdoc method
	* @name accept
	* @description
	* accept the modal
	*/
	public accept() {
		this.activeModal.close(true);
	}

	/**
	* @ngdoc method
	* @name dismiss
	* @description
	* dismiss modal
	*/
	public dismiss() {
		this.activeModal.dismiss();
	}
}
