import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailTemplatesListComponent } from './email-templates-list/email-templates-list.component';
import { EmailTemplatesListAddComponent } from './email-templates-list-add/email-templates-list-add.component';

import { EmailContainersComponent } from './email-containers/email-containers.component';
import { EmailContainerAddComponent } from './email-container-add/email-container-add.component';
// const routes: Routes = [];
const feesroutes: Routes = [
    { path: 'email-templates', component: EmailTemplatesListComponent },
    { path: 'email-templates-add', component: EmailTemplatesListAddComponent },
    { path: 'email-templates-add/:id', component:EmailTemplatesListAddComponent },
    { path: 'email-containers', component: EmailContainersComponent },
    { path: 'email-container-add', component: EmailContainerAddComponent },
    { path: 'email-container-add/:id', component:EmailContainerAddComponent },
];
@NgModule({
    imports: [RouterModule.forChild(feesroutes)],
    exports: [RouterModule]
})
export class EmailTemplatesRoutingModule { }