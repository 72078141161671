import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplatesRoutingModule } from './email-templates-routing.module';
import { EmailTemplatesListComponent } from './email-templates-list/email-templates-list.component';
import { DataTablesModule } from 'angular-datatables';
import { EmailTemplatesListAddComponent } from './email-templates-list-add/email-templates-list-add.component';
import { EmailTemplatesLabelsComponent } from './email-templates-labels/email-templates-labels.component';
import { EmailSmtpComponent } from './email-smtp/email-smtp.component';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { EmailContainersComponent } from './email-containers/email-containers.component';
import { EmailContainerAddComponent } from './email-container-add/email-container-add.component';
import { TinymceModule } from 'angular2-tinymce';

@NgModule({
  declarations: [EmailTemplatesListComponent, EmailTemplatesListAddComponent, EmailTemplatesLabelsComponent, EmailSmtpComponent, EmailPreviewComponent, EmailContainersComponent, EmailContainerAddComponent],
  imports: [
    CommonModule,
    EmailTemplatesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    DataTablesModule,
    TinymceModule.withConfig({}),
  ]
})
export class EmailTemplatesModule { }
