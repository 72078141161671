import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../../../common/confirmation-modal/confirmation-modal.component';
import { CommonService } from '../../../common/common.service';

class DataTablesResponse {
  data !: any[];
  draw !: number;
  recordsFiltered !: number;
  recordsTotal !: number;
}

@Component({
  selector: 'app-email-containers',
  templateUrl: './email-containers.component.html',
  styleUrls: ['./email-containers.component.scss']
})
export class EmailContainersComponent implements OnInit {

	dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective) dtElement: DataTableDirective | undefined;
    dtTrigger: Subject<any> = new Subject();
    list : any = [];

  	modalRef : any;
    loading : boolean = true;

    constructor(
    	private commonService: CommonService,
        private http: HttpClient,
        private modalService: NgbModal,
    ) {}

 	ngOnInit(): void {
        this.getDataTable();
  	}
      /**
     * @ngdoc method
     * @name getDataTable
     * @description
     * Set Datatable data
     * @returns void
     */
    getDataTable() {
        let that = this;
        this.dtOptions = {
            order: [[1, 'desc']],
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            searching: true,
            responsive: true,
            lengthChange: false,
            autoWidth: false,
            ajax: function (dataTablesParameters: any, callback: (data: { recordsTotal: number; recordsFiltered: number; data: any[] }) => void) {
			    that.http
			        .post<DataTablesResponse>(
			            environment.api + 'containers/list',
			            dataTablesParameters, {}
			        ).subscribe(resp => {
			            that.list = resp.data;
			            that.loading = false;
			            callback({
			                recordsTotal: resp.recordsTotal,
			                recordsFiltered: resp.recordsFiltered,
			                data: []
			            });
			        });
			},
            columns: [
                {data: 'name', width: '40%'},
                {data: 'created_at'},
                {data: 'id', orderable: false}
            ]
        };
    }
    /**
     * @ngdoc method
     * @name ngOnChanges
     * @description
     * reload invoice after plan change
     * @param {SimpleChanges} Object
     * @returns void
     */
    ngOnChanges(changes: SimpleChanges) {
        this.rerender();
    }

    /**
     * @ngdoc method
     * @name rerender
     * @description
     * Reload datatable
     * @returns void
     */
    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
        });
    }

    /**
     * @ngdoc method
     * @name delete
     * @description
     * Delete with confirmation
     * @param {id} String
     * @returns void
     */
    delete(id : string): void{
        this.modalRef = this.modalService.open(ConfirmationModalComponent, { backdrop: 'static', keyboard: false });
        this.modalRef.componentInstance.title = "Confirm";
        this.modalRef.componentInstance.message = "Are you sure to remove this container?";
        this.modalRef.result.then((result : any) => {
          if (result) {
              this.deleteApi(id);
          }
        }).catch((error : any) => {

        });
    }
    /**
     * @ngdoc method
     * @name deleteApi
     * @description
     * Delete api call
     * @param {id} String
     * @returns void
     */
    deleteApi(id:string){
        this.commonService.apiRequest('containers/delete/'+id, {}, 'delete').subscribe(
        (response : any) => {
            this.rerender();
            return;
        },
        (error : any) => {
            return;
        })
    }

}
